import { Box,  Grid, Typography } from "@mui/material";
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';

export const SuccessPage = () => (
    <Box
        sx={{
            mt: 1,
        }}
    >
        <Grid
            container
        >
            <Grid item md={2} xs={0}>
                {" "}
            </Grid>
            <Grid item md={8} xs={12} sx={{ pt:10  }}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                >
                    <Grid item md={4} xs={1} >
                        {" "}
                    </Grid>
                    <Grid item md={4} xs={10}>
                    <TaskAltSharpIcon sx={{ fontSize: 270, color: '#61dafb' }} />
                    </Grid>
                    <Grid item md={4} xs={1}>
                        {" "}
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Typography variant="h3" sx={{ py: 2 }}>
                        <strong>Clinical form has been submitted!</strong>
                    </Typography>
                    <Typography fontSize={24} sx={{ color: '#9e9e9e' }}>
                      Submissions are monitored between 8am and 10pm, 7 days.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={2} xs={0}>
                {" "}
            </Grid>
        </Grid>
    </Box>
);

