import { LabelMapper } from "../shared/constants";

export type OmitID<T> = Omit<T, "_id" | "id">;

export type Identity<T> = { [P in keyof T]: T[P] };

export type Replace<T, K extends keyof T, TReplace> = Identity<
  Pick<T, Exclude<keyof T, K>> & {
    [P in K]: TReplace;
  }
>;

export type KeyValue<T> = Record<keyof T, unknown>;

export type ListOptions<T> = {
  key: T;
  value: T | string;
  label?: string;
};

export const enumKeys = <T extends NonNullable<unknown>>(enums: T) =>
  Object.keys(enums) as [keyof T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumToKeyValuePair = (enums: any): any =>
  Object.entries(enums).map(([key, value]) => ({
    key,
    value: key,
    label: LabelMapper[value as string] ?? value,
  }));

export const readable = (key: string) => LabelMapper[key as string] ?? key;

export const questionable = (key: string) =>
  LabelMapper[`${key as string}_label`]
    ? LabelMapper[`${key as string}_label`]
    : LabelMapper[key as string] ?? key;

export type StringExtension<T extends string> = T;