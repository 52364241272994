import {
  IPatientPopulated,
  ISignedUrl,
} from "../interfaces/patient.interface";
import { loadPersistedToken, loginUrlJs } from "./auth.service";
import { AuthSuccessResponse } from "../interfaces/auth.interface";
import { IEngagement } from "../interfaces/engangement.interface";

let API_URL = "http://localhost:8081";
if (process.env.NODE_ENV === "production") {
  API_URL = "https://dev-api.nutrisphere.co.uk";
}

enum METHOD {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
}

interface RequestBody {
  type: "json" | "x-www-form-urlencoded";
  content: unknown;
}

export interface HttpResponse<T> {
  data: T;
  message: string;
}

export class HttpException extends Error {
  public status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export interface ErrorResponse {
  error: HttpException;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isErrorResponse = (obj: any): obj is ErrorResponse =>
  "error" in obj;

export type ErrorOrSuccess<T> = Promise<T> | Promise<ErrorResponse>;

const jsonBody = (content: unknown) =>
  ({
    type: "json",
    content,
  }) as RequestBody;

const makeRequest = <T>(
  method: METHOD,
  endPoint: string,
  body?: RequestBody | undefined,
  accessToken: string = loadPersistedToken()?.access_token as string
): ErrorOrSuccess<T> => {
  let authHeaders = {};
  if (accessToken) authHeaders = { Authorization: `Bearer ${accessToken}` };

  const request: RequestInit = {
    method,
    credentials: undefined,
    headers: {
      "Content-Type": "application/json",
      ...authHeaders,
    },
  };

  if ([METHOD.POST, METHOD.PUT, METHOD.PATCH].includes(method) && body)
    request.body = JSON.stringify(body.content);

  return fetch(API_URL + endPoint, request)
    .then(async (response) => {
      if (response.ok) {
        return response.json().then((json: HttpResponse<T>) => json.data);
      }

      if (response.status === 401) window.location.href = loginUrlJs();

      const json: HttpResponse<T> = await response.json();
      throw new HttpException(response.status, json.message);
    })
    .catch((error) => Promise.resolve({ error } as T));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const handleResponse = async (request: any) => {
//   const result = await request;

//   if (isErrorResponse(result)) {
//     if (result.error.status) window.location.href = loginUrlJs();
//   }
//   return result;
// };

interface IPatientLogin {
  first: string,
  last: string,
  dateOfBirth: string,
}
export const HTTP_SERVICE = {
  // loggedUser: (): ErrorOrSuccess<IUser> => makeRequest(METHOD.GET, `/me`),
  authenticate: (content: {
    token: string;
  }): ErrorOrSuccess<AuthSuccessResponse> =>
    makeRequest(METHOD.POST, `/google/login`, jsonBody(content), ""),
  getPatient: (id: string): ErrorOrSuccess<IPatientPopulated> =>
    makeRequest(METHOD.GET, `/patients/${id}`),
  createEngagement: (
    content: Partial<IEngagement>
  ): ErrorOrSuccess<IEngagement> =>
    makeRequest(METHOD.POST, `/engagements/`, jsonBody(content)),
  patientLogin: (
    content: IPatientLogin
  ): ErrorOrSuccess<AuthSuccessResponse> =>
    makeRequest(METHOD.POST, `/patient/login`, jsonBody(content)),
  authenticatedPatient: (): ErrorOrSuccess<IPatientPopulated> =>
    makeRequest(METHOD.GET, `/authenticated-patient`),
  updateProfileImage: (
    id: string,
    content: string
  ): ErrorOrSuccess<ISignedUrl> =>
    makeRequest(
      METHOD.GET,
      `/s3/upload-url?patientId=${id}&filename=${content}`
    ),
  uploadFile: (file: File, url: string): Promise<unknown> =>
    fetch(url, { method: "PUT", body: file }).then((response) => response),
};
