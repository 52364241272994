const URLS = {
  login: "/login"
};

const LabelMapper: { [key: string]: string } = {
  active: "Active", 
  alcohol_units_per_week: "Alcohol units consumed per week",
  appointment: "Appointment",
  average: "Average",
  blood_pressure_diastole: "Blood Pressure Diastole",
  blood_pressure_reading_form: "Bloodpressure Reading Form",
  blood_pressure_systole: "Blood Pressure Systole",
  blood_sugar_to_treat: "Blood sugar to treat hypo",
  bmi: "BMI",
  bp_checked: "BP Technique Checked",
  check_sugar_before_driving: "Check blood sugar before driving",
  clinical_reading: "Clinical Reading",
  clinical_review: "Clinical Assessment",
  confidence: "Confidence Level",
  confident: "Confident",
  continue: "Continue",
  currently_smoking: "Currently Smoking",
  date_last_hypo: "Date last hypo",
  diet: "Diet",
  do_not_know: "Do Not know",
  does_home_glucose: "Does home glucose monitoring",
  eat_drink_to_treat: "Eat drink to treat hypo",
  ex_smoker: "Ex-smoker",
  experiences_hypos: "Experienced hypos",
  fasting_at_time_of_hypo: "Fasting at time of hypo",
  glucose_below_2p8: "Glucose readings below 2p8mmol",
  glucose_below_4: "Glucose readings below 4mmol",
  goal: "Goal",
  good: "Good",
  height: "Height",
  less_than_1: "< 1",
  lifestyle_questionnaire_form: "Lifestyle Questionnaire Form",
  more_than_20: "20+",
  never: "Never",
  none: "None",
  not_very_active: "Not Very Active",
  num_times_sugar_less_4: "Num times in week blood sugar less than 4",
  no: "No",
  other: "Other",
  patient_blood_pressure_form_submission: "Blood Pressure Readings (Patient)",
  patient_diabetes_form_submission: "Diabetes Questionnaire (Patient)",
  patient_lifestyle_form_submission: "Lifestyle Questionnaire (Patient)",
  physical_activity: "Level of physical activity observable entity",
  poor: "Poor",
  present: "Present",
  pulse_rate: "Pulse Rate",
  quit: "Quit",
  reduce: "Reduce",
  slightly_active: "Slightly Active",
  smoking: "Smooking Status",
  smoking_year_quit: "Smooker Year Quit",
  socially: "Socially",
  symptoms_during_hypos: "Experienced hypos",
  ten_to_ninteen: "1-9",
  times_help_during_hypos: "Times needed help during hypos",
  times_needed_help_during_hypos_label:
    "How many times have they needed help during a hypo?",
  uncertain: "Uncertain",
  unconfident: "Unconfident",
  vaping: "Vaping status",
  very_active: "Very Active",
  very_confident: "Very Confident",
  very_unconfident: "Very Unconfident",
  weight: "Weight",
  yes: "Yes",
};

export { URLS, LabelMapper };
