import {
  Box,
  Button,
  Grid,
  Step,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { DateTime } from "../../../services/datetime.service";
import {
  EEngagementTypes,
  ESubmissionTypes,
  IEngagement,
} from "../../../interfaces/engangement.interface";
import {
  BloodPressureParameter,
  EBloodPressureParameter,
  EFormType,
  IReading,
} from "../../../interfaces/questionnaire.interface";
import { OmitID } from "../../../interfaces/utils";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../services/http.service";
import { getLoggedPatient, isAllFormSubmittedAtom, taskListAtom } from "../../../services/state.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bpForm = (formik: any, readingNumber: string) => (
  <FormikProvider value={formik}>
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong> {`${readingNumber} Blood Pressure Readings`} </strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12} >
            <Field
              as={TextField}
              label="Systolic 1 (mmHg)*"
              variant="outlined"
              margin="normal"
              type="number"
              name="systolic"
              value={formik.values.systolic}
              error={formik.touched.systolic && Boolean(formik.errors.systolic)}
              helperText={formik.touched.systolic && formik.errors.systolic}
              fullWidth
            />
          </Grid>

          <Grid item md={4} xs={12} >
            <Field
              as={TextField}
              label="Diastolic 1 (mmHg)*"
              variant="outlined"
              margin="normal"
              type="number"
              name="diastolic"
              value={formik.values.diastolic}
              error={
                formik.touched.diastolic && Boolean(formik.errors.diastolic)
              }
              helperText={formik.touched.diastolic && formik.errors.diastolic}
              fullWidth
            />
          </Grid>

          <Grid item md={4} xs={12} >
            <Field
              as={TextField}
              label="Pulse 1 (bpm)"
              variant="outlined"
              margin="normal"
              type="number"
              name="pulse"
              value={formik.values.pulse}
              error={formik.touched.pulse && Boolean(formik.errors.pulse)}
              helperText={formik.touched.pulse && formik.errors.pulse}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </FormikProvider>
);

const steps = ["first", "second"];

interface IBPReading {
  systolic: string;
  diastolic: string;
  pulse: string;
}
const dbReading: IBPReading[] = [];
export const BPMultiStepForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const patient = useRecoilValue(getLoggedPatient);
  const [tasks, setTasks] = useRecoilState(taskListAtom);
  const setAllFormSubmitted = useSetRecoilState(isAllFormSubmittedAtom);

  const convertToDBFormat = (
    param: BloodPressureParameter,
    value: string,
    datetime: string
  ): OmitID<IReading> => ({
    parameter: param,
    value: String(value),
    datetime,
  });

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const schema = z.object({
    systolic: z.number().gte(1).lte(999,{ message: "Systolic must be 3 digits" }),
    diastolic: z.number().gte(1).lte(999,{ message: "Diastolic must be 3 digits" }),
    pulse: z.number().gte(1).lte(999,{ message: "Pulse must be 3 digits" }),
  });

  const formik = useFormik({
    initialValues: {
      systolic: "",
      diastolic: "",
      pulse: "",
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values, { resetForm }) => {
      dbReading.push(values);
      if (activeStep === steps.length - 1) {
        const newClinicalEngagement = {
          type: EEngagementTypes.clinical_reading,
          user: "650aafcf41c00ef96a9cec96",
          patient: patient?._id,
          content: {
            formType: EFormType.blood_pressure_reading_form,
            submission: ESubmissionTypes.patient_blood_pressure_form_submission,
            readings: dbReading.map(({ systolic, diastolic, pulse }) => [
              convertToDBFormat(
                EBloodPressureParameter.blood_pressure_systole,
                systolic,
                DateTime.now().toString()
              ),
              convertToDBFormat(
                EBloodPressureParameter.blood_pressure_diastole,
                diastolic,
                DateTime.now().toString()
              ),
              convertToDBFormat(
                EBloodPressureParameter.pulse_rate,
                pulse,
                DateTime.now().toString()
              ),
            ]),
          },
          note: " ",
          datetime: DateTime.now().toString(),
        };

        HTTP_SERVICE.createEngagement(
          newClinicalEngagement as Partial<IEngagement>
        ).then((result: IEngagement | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            setActiveStep(0);
            setTasks(tasks.filter((v, i) => i !== 0));
            resetForm({ values: { systolic: "", diastolic: "", pulse: "" } });
            if (tasks.length === 1) {
              setAllFormSubmitted(true);
            }
          }
        });
      } else {
        resetForm({ values: { systolic: "", diastolic: "", pulse: "" } });
        setActiveStep((prevStep) => prevStep + 1);
      }
    },
  });

  const formContent = (step: number) => {
    switch (step) {
      case 0:
        return bpForm(formik, "First");
      case 1:
        return bpForm(formik, "Second");
      default:
        return <div>404: Not Found</div>;
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        mt: 1,
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item md={12} xs={12}>
          <Stepper activeStep={activeStep} orientation="horizontal">
            <Step>
              <Typography variant="h4" color='primary' >{`${String(activeStep + 1)} `} / {`${String(steps.length)}`}</Typography>
            </Step>
          </Stepper>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} sx={{ pt: "20px" }}>
            {formContent(activeStep)}
          </Grid>
          <Grid item xs={12}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mx: "10px", fontSize: 20 }}>
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button type="submit" sx={{ fontSize: 20 }}>Submit</Button>
            ) : (
              <Button type="submit" sx={{ fontSize: 20 }}>Next</Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
