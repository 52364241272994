import {
  Box,
  Stepper,
  Typography,
  Grid,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
} from "@mui/material";
import { FormikValues, useFormik } from "formik";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { DateTime } from "../../../services/datetime.service";
import {
  ConfidenceStatus,
  DietStatus,
  EFormType,
  ELifestyleParameter,
  IReading,
  LifestyleParameter,
  LifestyleQuestionnaire,
  PhysicalActivityStatus,
  SmokingStatus,
  VapingStatus,
  confidenceStatusOptions,
  dietStatusOptions,
  physicalActivityStatusOptions,
  smokingStatusOptions,
  vapingStatusOptions,
} from "../../../interfaces/questionnaire.interface";
import { ListOptions } from "../../../interfaces/utils";
import {
  EEngagementTypes,
  ESubmissionTypes,
  IEngagement,
} from "../../../interfaces/engangement.interface";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../services/http.service";
import { getLoggedPatient, isAllFormSubmittedAtom, taskListAtom } from "../../../services/state.service";

const weight = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>Weight?</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="How much do they currently weight?(kg)"
        variant="outlined"
        margin="normal"
        type="number"
        name="weight"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.weight}
        error={formik.touched.weight && Boolean(formik.errors.weight)}
        helperText={formik.touched.weight && formik.errors.weight}
        fullWidth
      />
    </Grid>
  </Grid>
);

const height = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>Height?</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="What is their height?(cm)"
        variant="outlined"
        margin="normal"
        type="number"
        name="height"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.height}
        error={formik.touched.height && Boolean(formik.errors.height)}
        helperText={formik.touched.height && formik.errors.height}
        fullWidth
      />
    </Grid>
  </Grid>
);

const confidence = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>What is their confidence level?</strong>
      </Typography>
    </Grid>
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="confidence"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.confidence}
    >
      {[...confidenceStatusOptions()].map(
        (i: ListOptions<ConfidenceStatus>) => (
          <FormControlLabel
            sx={{ mt: "16px", mb: "8px" }}
            key={i.key}
            value={i.value}
            control={<Radio />}
            label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> {i.label} </Typography>}
          />
        )
      )}
    </RadioGroup>
  </FormControl>
);

const goal = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>What is their goal?</strong>
      </Typography>
    </Grid>
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="goal"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.goal}
    >
      {[...physicalActivityStatusOptions()].map(
        (i: ListOptions<PhysicalActivityStatus>) => (
          <FormControlLabel
            sx={{ mt: "16px", mb: "8px" }}
            key={i.key}
            value={i.value}
            control={<Radio />}
            label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> {i.label} </Typography>}
          />
        )
      )}
    </RadioGroup>
  </FormControl>
);

const diet = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>Which best describes their diet?</strong>
      </Typography>
    </Grid>
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="diet"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.diet}
    >
      {[...dietStatusOptions()].map((i: ListOptions<DietStatus>) => (
        <FormControlLabel
          sx={{ mt: "16px", mb: "8px" }}
          key={i.key}
          value={i.value}
          control={<Radio />}
          label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> {i.label} </Typography>}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

const physicalActivity = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>What is their activity level?</strong>
      </Typography>
    </Grid>
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="physicalActivity"
      onChange={formik.handleChange}
      value={formik.values.physicalActivity}
    >
      {[...physicalActivityStatusOptions()].map(
        (i: ListOptions<PhysicalActivityStatus>) => (
          <FormControlLabel
            sx={{ mt: "16px", mb: "8px" }}
            key={i.key}
            value={i.value}
            control={<Radio />}
            label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> {i.label} </Typography>}
          />
        )
      )}
    </RadioGroup>
  </FormControl>
);

const vaping = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>Do they vape?</strong>
      </Typography>
    </Grid>
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="vaping"
      onChange={formik.handleChange}
      value={formik.values.vaping}
    >
      {[...vapingStatusOptions()].map((i: ListOptions<VapingStatus>) => (
        <FormControlLabel
          sx={{ mt: "16px", mb: "8px" }}
          key={i.key}
          value={i.value}
          control={<Radio />}
          label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> {i.label} </Typography>}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

const smoking = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>Do they smoke?</strong>
      </Typography>
    </Grid>
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="smoking"
      onChange={formik.handleChange}
      value={formik.values.smoking}
    >
      {[...smokingStatusOptions()].map((i: ListOptions<SmokingStatus>) => (
        <FormControlLabel
          sx={{ mt: "16px", mb: "8px" }}
          key={i.key}
          value={i.value}
          control={<Radio />}
          label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> {i.label} </Typography>}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

const smokingYearQuit = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
        <strong>How many years to quit smoking?</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Year Quit"
        variant="outlined"
        margin="normal"
        type="number"
        name="smokingYearQuit"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.smokingYearQuit}
        error={
          formik.touched.smokingYearQuit &&
          Boolean(formik.errors.smokingYearQuit)
        }
        helperText={
          formik.touched.smokingYearQuit && formik.errors.smokingYearQuit
        }
        fullWidth
      />
    </Grid>
  </Grid>
);

const alcoholUnitsPerWeek = (formik: FormikValues) => (
  <Grid item xs={12}>
    <FormControl>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} >
          <strong>
            {" "}
            How many units of alcohole do they drink in normal week?
          </strong>
        </Typography>
      </Grid>
      <TextField
        label="Units"
        variant="outlined"
        margin="normal"
        type="number"
        name="alcoholUnitsPerWeek"
        value={formik.values.alcoholUnitsPerWeek}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.alcoholUnitsPerWeek &&
          Boolean(formik.errors.alcoholUnitsPerWeek)
        }
        helperText={
          formik.touched.alcoholUnitsPerWeek &&
          formik.errors.alcoholUnitsPerWeek
        }
        fullWidth
      />
    </FormControl>
  </Grid>
);

const steps = [
  "weight",
  "height",
  "confidence",
  "goal",
  "diet",
  "physicalActivity",
  "vaping",
  "smoking",
  "smokingYearQuit",
  "alcoholUnitsPerWeek",
];

export const LifeStyleMultiStepForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const patient = useRecoilValue(getLoggedPatient);
  const [tasks, setTasks] = useRecoilState(taskListAtom);
  const  setAllFormSubmitted = useSetRecoilState(isAllFormSubmittedAtom);

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const convertToDBFormat = (
    param: LifestyleParameter,
    value: string,
    datetime?: string | undefined
  ): IReading[] => [
    {
      parameter: param,
      value: String(value),
      datetime: datetime ?? "",
    },
  ];

  const schema = z.object({
    weight: z.number({required_error: "Weight? Required"}).gte(1).lte(9999,{ message: "Weight must contain at most 4 digit(s)" }),
    height: z.number({required_error: "Height? Required"}).gte(1).lte(999,{ message: "Height must contain at most 3 digit(s)" }),
    confidence: z.string(),
    goal: z.string(),
    diet: z.string(),
    physicalActivity: z.string(),
    vaping: z.string(),
    smoking: z.string(),
    smokingYearQuit: z.number({required_error: "How many years to quit smoking? Required"}).gte(1).lte(99, { message: "How many years to quit smoking? Must contain at most 2 digit(s)" }),
    alcoholUnitsPerWeek: z.number(
      {required_error: "How many units of alcohole do they drink in normal week? Required"}
    ).gte(1).lte(999, { message: "How many units of alcohole do they drink in normal week? Must contain at most 3 digit(s)" }),
  });

  type TLifestyleQuestionnaire = Omit<
    LifestyleQuestionnaire,
    "alcoholUnitsPerWeek"
  > & {
    alcoholUnitsPerWeek: string | undefined;
  };

  const initialValues: TLifestyleQuestionnaire = {
    weight: "",
    height: "",
    bmi: "",
    confidence: "do_not_know",
    goal: "do_not_know",
    diet: "do_not_know",
    physicalActivity: "do_not_know",
    vaping: "do_not_know",
    smoking: "do_not_know",
    smokingYearQuit: "",
    alcoholUnitsPerWeek: "",
  };

  const formik = useFormik<TLifestyleQuestionnaire>({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values, { resetForm }) => {
      if (activeStep === steps.length - 1) {
        const newClinicalEngagement = {
          type: EEngagementTypes.clinical_reading,
          user: "650aafcf41c00ef96a9cec96",
          patient: patient?._id,
          content: {
            formType: EFormType.lifestyle_questionnaire_form,
            submission: ESubmissionTypes.patient_lifestyle_form_submission,
            readings: [
              convertToDBFormat(
                ELifestyleParameter.weight,
                values.weight as string
              ),
              convertToDBFormat(
                ELifestyleParameter.height,
                values.weight as string
              ),
              convertToDBFormat(ELifestyleParameter.bmi, values.bmi as string),
              convertToDBFormat(
                ELifestyleParameter.confidence,
                values.confidence as string
              ),
              convertToDBFormat(
                ELifestyleParameter.goal,
                values.goal as string
              ),
              convertToDBFormat(
                ELifestyleParameter.diet,
                values.diet as string
              ),
              convertToDBFormat(
                ELifestyleParameter.physical_activity,
                values.physicalActivity as string
              ),
              convertToDBFormat(
                ELifestyleParameter.vaping,
                values.vaping as string
              ),
              convertToDBFormat(
                ELifestyleParameter.smoking,
                values.smoking as string
              ),
              convertToDBFormat(
                ELifestyleParameter.smoking_year_quit,
                values.smokingYearQuit as string
              ),
              convertToDBFormat(
                ELifestyleParameter.alcohol_units_per_week,
                values.alcoholUnitsPerWeek as string
              ),
            ],
          },
          note: " ",
          datetime: DateTime.now().toString(),
        };

        HTTP_SERVICE.createEngagement(
          newClinicalEngagement as Partial<IEngagement>
        ).then((result: IEngagement | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            resetForm({ values: initialValues });
            setActiveStep(0);
            setTasks(tasks.filter((v,i)=>i !==0));
            if(tasks.length === 1){
              setAllFormSubmitted(true);
            }
          }
        });
      }
    },
  });

  const formContent = (step: number) => {
    switch (step) {
      case 0:
        return weight(formik);
      case 1:
        return height(formik);
      case 2:
        return confidence(formik);
      case 3:
        return goal(formik);
      case 4:
        return diet(formik);
      case 5:
        return physicalActivity(formik);
      case 6:
        return vaping(formik);
      case 7:
        return smoking(formik);
      case 8:
        return smokingYearQuit(formik);
      case 9:
        return alcoholUnitsPerWeek(formik);
      default:
        return <div>404: Not Found</div>;
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        mt: 1,
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item md={12} xs={12}>
          <Stepper activeStep={activeStep} orientation="horizontal">
            <Step>
              <Typography variant="h4" color="primary">{`${activeStep + 1} /${
                steps.length
              }`}</Typography>
            </Step>
          </Stepper>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} sx={{ pt: "20px" }}>
            {formContent(activeStep)}
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mx: "10px", fontSize: 20 }}
              size="large"
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={formik.submitForm}
                size="large"
                sx={{ fontSize: 20 }}
              >
                Submit
              </Button>
            ) : (
              <Button onClick={handleNext} size="large" sx={{ fontSize: 20 }}>
                Next
              </Button>
            )}
          </Grid>
        </Grid>

        {activeStep === steps.length - 1 &&
          formik.errors &&
          Object.values(formik.errors).map((errorMessage, index) => (
            <Typography
              key={index}
              variant="h6"
              component="div"
              sx={{ color: "error.main" }}
            >
              {errorMessage}
            </Typography>
          ))}
      </form>
    </Box>
  );
};
