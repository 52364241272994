import { ReactElement, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useRecoilValue } from "recoil";
import { patientAtom } from "../../../services/state.service";
import { removeToken } from "../../../services/auth.service";

type AppLayoutProps = { page: ReactElement };

const Copyright = () => (
  <Typography variant="body2" color="text.secondary" align="center">
    {"Copyright © "}
    <Link color="inherit" href="/">
      Nutrisphere Patient
    </Link>{" "}
    {new Date().getFullYear()}
  </Typography>
);

const AppLayout = ({ page }: AppLayoutProps) => {
  const [menuItemElement, setMenuItemElement] = useState<null | HTMLElement>(null);
  const patient = useRecoilValue(patientAtom);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuItemElement(event.currentTarget);
  };

  const handleLogOut = () => {
    removeToken();
    setMenuItemElement(null);
    window.location.href = "/";
  };

  const gotoProfile = () => {
    navigate("/profile");
    setMenuItemElement(null);
  };
  return (
    <>
      <AppBar position="fixed" sx={{ background: "primary" }}>
        <Toolbar>
          <Link href="/">
            <Box
              component="img"
              sx={{ height: 48 }}
              alt="Logo"
              src='/logo.png'
            />
          </Link>

          {patient && (
            <Box style={{ width: "80%" }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <PersonSharpIcon style={{ color: "white" }} />
                  <Typography sx={{ paddingLeft: 1, display: { lg: 'block', md: 'block', sm: 'none', xs: 'none', } }} color="white" noWrap>
                    {` ${patient.name.first} ${patient.name.last}`}
                  </Typography>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={menuItemElement}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(menuItemElement)}
                >
                  <MenuItem onClick={gotoProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleLogOut}>LogOut</MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {/* Page Content */}
      <Box sx={{ my: 2, height: "auto" }}>
        <Container sx={{ py: 8 }} maxWidth="xl">
          {page}
        </Container>
      </Box>
      {/* End Page Content */}
      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Health Care Solution
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </>
  );
};

export default AppLayout;
