import { Card, CardMedia, Typography } from "@mui/material";

type BannerProps = {
    imageUrl: string;
};

export const Banner = ({ imageUrl }: BannerProps) => (
    <Card >
    <div style={{ position: "relative" }} >
        <CardMedia
            sx={{ maxWidth: "xl", height: 220, border: '1px', borderRadius: 1 }}
            image={imageUrl}
            title="green iguana"
        />
        <Typography variant='h2' style={{position: "absolute", color: "white",top: '20%',left: "50%",transform: "translateX(-50%)",}} ><strong>MEDICAL CENTER</strong></Typography>
    </div>
    </Card>
);
