import { useEffect, useReducer, useState } from "react";
import { useFormik } from "formik";
import * as z from "zod";
import MuiPhoneNumber from 'mui-phone-number';
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useRecoilState, useRecoilValue } from "recoil";
import isEqual from "lodash.isequal";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import {
  IPatientPhoneNumbers,
  IPatientPopulated,
  ISignedUrl,
} from "../../../interfaces/patient.interface";
import { IAddress, IName } from "../../../interfaces/common.interface";
import { localFormat } from "../../../services/datetime.service";
import { getLoggedPatient, patientAtom } from "../../../services/state.service";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../services/http.service";

interface PatientDetailFromFields {
  name: Partial<IName>;
  dateOfBirth: string;
  gender: string;
  emailAddr: string;
  availabilityHours: string;
  postalAddress: Partial<IAddress>;
  preferredLanguage: string;
  phoneNumbers: Partial<IPatientPhoneNumbers>;
}

const PatientDetailFrom = () => {
  const loggedPatient = useRecoilValue(getLoggedPatient);
  const [patient, setPatient] = useRecoilState(patientAtom);
  const [uploadBar, setUploadBar] = useState(false);
  const [uploadBtnStyle, setUploadBtnStyle] = useState({ display: "none" });
  const [file, setFile] = useState<File>();

  const patientInfo: IPatientPopulated = {
    ...patient,
  };

  const [editPatient, updatePatient] = useReducer(
    (
      {
        name,
        dateOfBirth,
        gender,
        emailAddr,
        availabilityHours,
        postalAddress,
        preferredLanguage,
        phoneNumbers,
      }: IPatientPopulated,
      {
        name: updateName,
        postalAddress: updateAddress,
        phoneNumbers: updatePhoneNumberss,
        ...updates
      }: Partial<PatientDetailFromFields>
    ) =>
      ({
        dateOfBirth,
        gender,
        emailAddr,
        availabilityHours,
        preferredLanguage,
        name: {
          ...name,
          ...updateName,
        },
        postalAddress: {
          ...postalAddress,
          ...updateAddress,
        },
        phoneNumbers: {
          ...phoneNumbers,
          ...updatePhoneNumberss,
        },
        ...updates,
      }) as IPatientPopulated,
      patientInfo
  );

  // It will automatically fill form if page refresh
  useEffect (()=>{
    setPatient(loggedPatient as IPatientPopulated);
    updatePatient({...loggedPatient});
   },[]);


  const schema = z.object({
    name: z.object({
      nick: z.string().min(4).max(50),
    }),
    phoneNumbers: z.object({
      mobile1:  z.number().gte(1).lte(9999999999999,{ message: "Mobile Number 1 must contain at most 13 digit(s)" }),
      mobile2: z.number().gte(1).lte(9999999999999,{ message: "Mobile Number 2 must contain at most 13 digit(s)" }).optional(),
      landline: z.number().gte(1).lte(9999999999999,{ message: "Landline Number must contain at most 13 digit(s)" }).optional(),
    }),
    emailAddr: z.string().email(),
    language: z.string(),
    gender: z.string(),
    postalAddress: z.object({
      line1: z.string().min(4).max(1000),
      line2: z.string().min(4).max(1000).optional(),
      city: z.string().min(4).max(300),
      county: z.string().min(4).max(100),
      country: z.string().min(4).max(100),
      postalCode: z.string().min(4).max(50),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: {
        nick: editPatient.name?.nick,
        first: editPatient.name?.first,
        last: editPatient.name?.last,
      },
      phoneNumbers: {
        mobile1: Number(editPatient?.phoneNumbers?.mobile1),
        mobile2: Number(editPatient?.phoneNumbers?.mobile2),
        landline: Number(editPatient?.phoneNumbers?.landline),
      },
      emailAddr: editPatient.emailAddr,
      language: editPatient.preferredLanguage,
      gender: editPatient.gender,
      postalAddress: {
        line1: editPatient.postalAddress?.line1,
        line2: editPatient.postalAddress?.line2,
        city: editPatient.postalAddress?.city,
        county: editPatient.postalAddress?.county,
        country: editPatient.postalAddress?.country,
        postalCode: editPatient.postalAddress?.postalCode,
      },
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: () => {
      const dataUpdates: Partial<PatientDetailFromFields> = {};
      [
        "dateOfBirth",
        "gender",
        "emailAddr",
        "availabilityHours",
        "preferredLanguage",
        "phoneNumbers",
        "name",
        "postalAddress",
      ].forEach((key) => {
        if (
          isEqual(
            editPatient[key as keyof IPatientPopulated],
            patientInfo[key as keyof PatientDetailFromFields]
          )
        )
          return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedValue = editPatient[key as keyof IPatientPopulated] as any;
        dataUpdates[key as keyof PatientDetailFromFields] = updatedValue;
      });

      // HTTP_SERVICE.updatePatient(
      //   patientWithHydratedPhone._id,
      //   dataUpdates as Partial<IPatient>
      // ).then((result: IPatientPopulated | ErrorResponse): void => {
      //   if (!isErrorResponse(result)) {
      //     handleUpdateResponse(result);
      //   }
      // });
    },
  });

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = e.target.files as FileList;
    const selectedFile = inputFile?.[0];
    if (
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpeg"
    ) {
      setFile(selectedFile);
    }
  };

  useEffect(() => {
    if (file?.name !== undefined) {
      setUploadBar(true);
      HTTP_SERVICE.updateProfileImage(patient._id, file?.name).then(
        (result: ISignedUrl | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            HTTP_SERVICE.uploadFile(file, result.putUrl).then(() => {
              setUploadBar(false);
              setPatient({ ...patient, imageKey: result.getUrl });
            });
          }
        }
      );
    }
  }, [file, setFile]);

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ pb: 2, pt: 1 }}>
          Patient Information
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item>
            <Card>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent
                  sx={{ flex: "1 0 auto" }}
                  onMouseEnter={() => {
                    setUploadBtnStyle({ display: "block" });
                  }}
                  onMouseLeave={() => {
                    setUploadBtnStyle({ display: "none" });
                  }}
                >
                  <Box sx={{ display: 'flex', position: "relative" }}>
                    <CardMedia
                      sx={{ width: 120, height: 120, border: '1px', borderRadius: 1, }}
                      image={patient?.imageKey ? `${patient?.imageKey}` : "/profile.png"}
                      title="green iguana"
                    />
                    <Button component="label" variant="text" size="small" sx={{ mt: 0, position: "absolute", '&.MuiButton-root:hover': { bgcolor: 'transparent' } }} style={uploadBtnStyle}>
                      <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                      <CloudUploadSharpIcon />
                    </Button>
                  </Box>

                  {uploadBar && (
                    <LinearProgress />
                  )}
                </CardContent>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              type="text"
              name="name.first"
              value={`${editPatient.name?.first ?? '' }`}
              onChange={(e) => {
                updatePatient({ name: { first: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name?.first && Boolean(formik.errors.name?.first)
              }
              helperText={
                formik.touched.name?.first && formik.errors.name?.first
              }
              fullWidth
              disabled
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              type="text"
              name="name.last"
              value={`${editPatient.name?.last ?? ''}`}
              onChange={(e) => {
                updatePatient({ name: { last: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name?.last && Boolean(formik.errors.name?.last)
              }
              helperText={formik.touched.name?.last && formik.errors.name?.last}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <TextField
              label="Preferred Name"
              variant="outlined"
              size="small"
              type="text"
              name="name.nick"
              value={`${editPatient.name?.nick ?? '' }`}
              onChange={(e) => {
                updatePatient({ name: { nick: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name?.nick && Boolean(formik.errors.name?.nick)
              }
              helperText={formik.touched.name?.nick && formik.errors.name?.nick}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              label="Preferred language"
              variant="outlined"
              size="small"
              name="language"
              value={`${editPatient.preferredLanguage ?? '' }`}
              select
              onChange={(e) => {
                updatePatient({ preferredLanguage: e.target.value });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.language && Boolean(formik.errors.language)}
              helperText={formik.touched.language && formik.errors.language}
              fullWidth
              disabled
            >
              <MenuItem key="" value="">
                No Selected
              </MenuItem>
              <MenuItem key="english" value="English">
                English
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              label="Date of Birth"
              variant="outlined"
              size="small"
              type="date"
              name="dob"
              value={localFormat(editPatient.dateOfBirth) ?? ''}
              onChange={(e) => {
                updatePatient({ dateOfBirth: e.target.value });
              }}
              onBlur={formik.handleBlur}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              label="Gender"
              variant="outlined"
              size="small"
              name="gender"
              value={`${editPatient.gender ?? ''}`}
              select
              onChange={(e) => {
                updatePatient({ gender: e.target.value });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helperText={formik.touched.gender && formik.errors.gender}
              fullWidth
              disabled
            >
              <MenuItem key="empty" value="">
                Not Selected
              </MenuItem>
              <MenuItem key="male" value="male">
                Male
              </MenuItem>
              <MenuItem key="female" value="female">
                Female
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Contact</Typography>
          </Grid>

          <Grid item xs={6} >
            <MuiPhoneNumber
                variant="outlined"
                fullWidth
                size="small"
                name="phoneNumbers.mobile1"
                label="Mobile Number 1"
                defaultCountry="gb"
                onlyCountries={['gb']}
                value={`${editPatient?.phoneNumbers?.mobile1}`}
                error={
                  formik.touched.phoneNumbers?.mobile1 && Boolean(formik.errors.phoneNumbers?.mobile1)
                }
                helperText={formik.touched.phoneNumbers?.mobile1 && formik.errors.phoneNumbers?.mobile1}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue("phoneNumbers.mobile1", e);
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled
            />   
          </Grid>           


          <Grid item xs={6} >
            <MuiPhoneNumber
                variant="outlined"
                fullWidth
                size="small"
                name="phoneNumbers.mobile2"
                label="Mobile Number 2"
                defaultCountry="gb"
                onlyCountries={['gb']}
                value={`${editPatient?.phoneNumbers?.mobile2}`}
                error={
                  formik.touched.phoneNumbers?.mobile2 && Boolean(formik.errors.phoneNumbers?.mobile2)
                }
                helperText={formik.touched.phoneNumbers?.mobile2 && formik.errors.phoneNumbers?.mobile2}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue("phoneNumbers.mobile2", e);
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled
            />   
          </Grid>

          <Grid item xs={6} >
            <MuiPhoneNumber
                variant="outlined"
                fullWidth
                size="small"
                name="phoneNumbers.landline"
                label="Landline Number"
                defaultCountry="gb"
                onlyCountries={['gb']}
                value={`${editPatient?.phoneNumbers?.landline}`}
                error={
                  formik.touched.phoneNumbers?.landline && Boolean(formik.errors.phoneNumbers?.landline)
                }
                helperText={formik.touched.phoneNumbers?.landline && formik.errors.phoneNumbers?.landline}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue("phoneNumbers.landline", e);
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled
            />   
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Email Address"
              variant="outlined"
              size="small"
              type="email"
              name="emailAddr"
              value={`${editPatient.emailAddr ?? ''}`}
              onChange={(e) => {
                updatePatient({ emailAddr: e.target.value });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.emailAddr && Boolean(formik.errors.emailAddr)
              }
              helperText={formik.touched.emailAddr && formik.errors.emailAddr}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Home Address</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Address line 1"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.line1"
              value={editPatient.postalAddress?.line1 ?? ''}
              onChange={(e) => {
                updatePatient({ postalAddress: { line1: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.line1 &&
                Boolean(formik.errors.postalAddress?.line1)
              }
              helperText={
                formik.touched.postalAddress?.line1 &&
                formik.errors.postalAddress?.line1
              }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Address line 2"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.line2"
              value={editPatient.postalAddress?.line2 ?? ''}
              onChange={(e) => {
                updatePatient({ postalAddress: { line2: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.line2 &&
                Boolean(formik.errors.postalAddress?.line2)
              }
              helperText={
                formik.touched.postalAddress?.line2 &&
                formik.errors.postalAddress?.line2
              }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Town/City"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.city"
              value={editPatient.postalAddress?.city ?? ''}
              onChange={(e) => {
                updatePatient({ postalAddress: { city: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.city &&
                Boolean(formik.errors.postalAddress?.city)
              }
              helperText={
                formik.touched.postalAddress?.city &&
                formik.errors.postalAddress?.city
              }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="County"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress.county"
              value={editPatient.postalAddress?.county ?? ''}
              onChange={(e) => {
                updatePatient({ postalAddress: { county: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.county &&
                Boolean(formik.errors.postalAddress?.county)
              }
              helperText={
                formik.touched.postalAddress?.county &&
                formik.errors.postalAddress?.county
              }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress?.country"
              value={editPatient.postalAddress?.country ?? ''}
              onChange={(e) => {
                updatePatient({ postalAddress: { country: e.target.value } });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.country &&
                Boolean(formik.errors.postalAddress?.country)
              }
              helperText={
                formik.touched.postalAddress?.country &&
                formik.errors.postalAddress?.country
              }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Postcode"
              variant="outlined"
              size="small"
              type="text"
              name="postalAddress?.postalCode"
              value={editPatient.postalAddress?.postalCode ?? ''}
              onChange={(e) => {
                updatePatient({
                  postalAddress: { postalCode: e.target.value },
                });
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalAddress?.postalCode &&
                Boolean(formik.errors.postalAddress?.postalCode)
              }
              helperText={
                formik.touched.postalAddress?.postalCode &&
                formik.errors.postalAddress?.postalCode
              }
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PatientDetailFrom;
