import { useState } from "react";
import { FormikValues, useFormik } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

import {
  Box,
  Stepper,
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  MenuItem,
  Step,
} from "@mui/material";
import { DateTime, localFormat } from "../../../services/datetime.service";
import {
  DiabetesParameter,
  DiabetesQuestionnaire,
  EDiabetesParameter,
  EFormType,
  IReading,
} from "../../../interfaces/questionnaire.interface";
import {
  EEngagementTypes,
  ESubmissionTypes,
  IEngagement,
} from "../../../interfaces/engangement.interface";
import { OmitID } from "../../../interfaces/utils";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../services/http.service";
import { getLoggedPatient, isAllFormSubmittedAtom, taskListAtom } from "../../../services/state.service";

const experiencesHypos = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Patient has experienced hypo?</strong></Typography>
    </Grid>
    <Grid item xs={1}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="experiencesHypos"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.experiencesHypos}
      >
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="yes" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }} > Yes </Typography>} />
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="no" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> No </Typography>} />
      </RadioGroup>
    </Grid>
  </FormControl>
);
const timesHelpDuringHypos = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>How many times have they needed help during hypo?</strong></Typography>
    </Grid>
    <TextField
      variant="outlined"
      type="number"
      name="timesHelpDuringHypos"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.timesHelpDuringHypos}
      error={
        formik.touched.timesHelpDuringHypos &&
        Boolean(formik.errors.timesHelpDuringHypos)
      }
      helperText={
        formik.touched.timesHelpDuringHypos &&
        formik.errors.timesHelpDuringHypos
      }
      margin="normal"
      fullWidth
    />
  </Grid>
);
const eatDrinkToTreat = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>What do they eat or drink to treat hypo?</strong></Typography>
    </Grid>
    <TextField
      variant="outlined"
      type="text"
      margin="normal"
      name="eatDrinkToTreat"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.eatDrinkToTreat}
      error={
        formik.touched.eatDrinkToTreat && Boolean(formik.errors.eatDrinkToTreat)
      }
      helperText={
        formik.touched.eatDrinkToTreat && formik.errors.eatDrinkToTreat
      }
      fullWidth
    />
  </Grid>
);
const doesHomeGlucose = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Patient does home glucose monitoring?</strong></Typography>
    </Grid>
    <Grid item xs={1}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="doesHomeGlucose"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.doesHomeGlucose}
      >
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="yes" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }} > Yes </Typography>} />
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="no" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }} > No </Typography>} />
      </RadioGroup>
    </Grid>
  </FormControl>
);
const glucoseBelow4 = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Any reading below 4mmol/l?</strong></Typography>
    </Grid>
    <Grid item xs={1}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="glucoseBelow4"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.glucoseBelow4}
      >
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="yes" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> Yes </Typography>} />
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="no" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> No </Typography>} />
      </RadioGroup>
    </Grid>
  </FormControl>
);
const glucoseBelow2p8 = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Any reading below 2.8mmol/l?</strong></Typography>
    </Grid>
    <Grid item xs={1}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="glucoseBelow2p8"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.glucoseBelow2p8}
      >
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="yes" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> Yes </Typography>} />
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="no" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> No </Typography>} />
      </RadioGroup>
    </Grid>
  </FormControl>
);
const symptomsDuringHypos = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Symptoms experienced during a hypo</strong></Typography>
    </Grid>
    <TextField
      name="symptomsDuringHypos"
      onChange={formik.handleChange}
      error={
        formik.touched.symptomsDuringHypos &&
        Boolean(formik.errors.symptomsDuringHypos)
      }
      helperText={
        formik.touched.symptomsDuringHypos && formik.errors.symptomsDuringHypos
      }
      value={formik.values.symptomsDuringHypos}
      fullWidth
      select
      margin="normal"
    >
      <MenuItem key="yes" value="yes">
        Yes
      </MenuItem>
      <MenuItem key="no" value="no">
        No
      </MenuItem>
    </TextField>
  </Grid>
);
const dateLastHypo = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>When was their hypo?</strong></Typography>
    </Grid>
    <TextField
      variant="outlined"
      type="date"
      margin="normal"
      name="dateLastHypo"
      value={formik.values.dateLastHypo}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.dateLastHypo && Boolean(formik.errors.dateLastHypo)}
      helperText={formik.touched.dateLastHypo && formik.errors.dateLastHypo}
      fullWidth
    />
  </Grid>
);
const bloodSugarToTreat = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>At what glucose level do they treat hypo?</strong></Typography>
    </Grid>
    <TextField
      variant="outlined"
      type="number"
      margin="normal"
      name="bloodSugarToTreat"
      onChange={formik.handleChange}
      value={formik.values.bloodSugarToTreat}
      onBlur={formik.handleBlur}
      error={
        formik.touched.bloodSugarToTreat &&
        Boolean(formik.errors.bloodSugarToTreat)
      }
      helperText={
        formik.touched.bloodSugarToTreat && formik.errors.bloodSugarToTreat
      }
      fullWidth
    />
  </Grid>
);
const numTimesSugarLess4 = (formik: FormikValues) => (
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Number of times a week blood sugar is {`${'<'}`}4?</strong></Typography>
    </Grid>
    <TextField
      variant="outlined"
      type="number"
      margin="normal"
      name="numTimesSugarLess4"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.numTimesSugarLess4}
      error={
        formik.touched.numTimesSugarLess4 &&
        Boolean(formik.errors.numTimesSugarLess4)
      }
      helperText={
        formik.touched.numTimesSugarLess4 && formik.errors.numTimesSugarLess4
      }
      fullWidth
    />
  </Grid>
);
const checkSugarBeforeDriving = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Do you check your blood sugar before driving?</strong></Typography>
    </Grid>
    <Grid item xs={1}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="checkSugarBeforeDriving"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.checkSugarBeforeDriving}
      >
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="yes" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> Yes </Typography>} />
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="no" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> No </Typography>} />
      </RadioGroup>
    </Grid>
  </FormControl>
);
const fastingAtTimeOfHypo = (formik: FormikValues) => (
  <FormControl>
    <Grid item xs={12}>
      <Typography sx={{ fontSize: {lg: 45, md: 35, sm: 30, xs: 25 } }} ><strong>Was patient fasting at time of hypo?</strong></Typography>
    </Grid>
    <Grid item xs={1}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="fastingAtTimeOfHypo"
        onChange={formik.handleChange}
        value={formik.values.fastingAtTimeOfHypo}
        onBlur={formik.handleBlur}
        defaultValue={formik.values.fastingAtTimeOfHypo}
      >
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="yes" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> Yes </Typography>} />
        <FormControlLabel sx={{ mt: "16px", mb: "8px" }} value="no" control={<Radio />} label={<Typography sx={{ fontSize: {lg: 35, md: 30, sm: 25, xs: 20 } }}> No </Typography>} />
      </RadioGroup>
    </Grid>
  </FormControl>

);

const steps = [
  "doesHomeGlucose",
  "glucoseBelow4",
  "glucoseBelow2p8",
  "experiencesHypos",
  "symptomsDuringHypos",
  "timesHelpDuringHypos",
  "dateLastHypo",
  "bloodSugarToTreat",
  "numTimesSugarLess4",
  "eatDrinkToTreat",
  "checkSugarBeforeDriving",
  "fastingAtTimeOfHypo",
];

export const DiabetesMultiStepForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const patient = useRecoilValue(getLoggedPatient);
  const [tasks, setTasks] = useRecoilState(taskListAtom);
  const  setAllFormSubmitted = useSetRecoilState(isAllFormSubmittedAtom);
  
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const convertToDBFormat = (
    param: DiabetesParameter,
    value: string,
    datetime?: string | undefined
  ): OmitID<IReading[]> => [
      {
        parameter: param,
        value: String(value),
        datetime: datetime ?? "",
      },
    ];
  const schema = z.object({
    doesHomeGlucose: z.string(),
    glucoseBelow4: z.string(),
    glucoseBelow2p8: z.string(),
    experiencesHypos: z.string(),
    symptomsDuringHypos: z.string(),
    timesHelpDuringHypos: z.number(
      { required_error: "How many times have they needed help during hypo? Required" }
      ).gte(1).lte(999,{ message: "How many times have they needed help during hypo? Must contain at most 3 digit(s)" }),
    dateLastHypo: z.coerce.date(),
    bloodSugarToTreat: z.number(
      { required_error: "At what glucose level do they treat hypo? Required" }
    ).gte(1).lte(999,{ message: "At what glucose level do they treat hypo? Must contain at most 3 digit(s)" }),
    numTimesSugarLess4: z.number(
      { required_error: "Number of times a week blood sugar is <4? Required" }
    ).gte(1).lte(99,{ message: "Number of times a week blood sugar is <4? Must contain at most 2 digit(s)" }),
    eatDrinkToTreat: z.string({ required_error :"What do they eat or drink to treat hypo? Required" }).max(1500),
    checkSugarBeforeDriving: z.string(),
    fastingAtTimeOfHypo: z.string(),
  });

  const initialValues: DiabetesQuestionnaire = {
    experiencesHypos: "yes",
    doesHomeGlucose: "yes",
    glucoseBelow4: "yes",
    timesHelpDuringHypos: "",
    eatDrinkToTreat: "",
    glucoseBelow2p8: "yes",
    symptomsDuringHypos: "yes",
    dateLastHypo: `${localFormat(new Date().toISOString())}`,
    bloodSugarToTreat: "",
    numTimesSugarLess4: "",
    checkSugarBeforeDriving: "yes",
    fastingAtTimeOfHypo: "yes",
  };

  const formik = useFormik<DiabetesQuestionnaire>({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (value, { resetForm }) => {
      if (activeStep === steps.length - 1) {
        const newClinicalEngagement = {
          type: EEngagementTypes.clinical_reading,
          user: "650aafcf41c00ef96a9cec96",
          patient: patient?._id,
          content: {
            formType: EFormType.diabetes_questionnaire_form,
            submission: ESubmissionTypes.patient_diabetes_form_submission,
            readings: [
              convertToDBFormat(
                EDiabetesParameter.blood_sugar_to_treat,
                value.bloodSugarToTreat as string
              ),
              convertToDBFormat(
                EDiabetesParameter.check_sugar_before_driving,
                value.checkSugarBeforeDriving as string
              ),
              convertToDBFormat(
                EDiabetesParameter.date_last_hypo,
                value.dateLastHypo as string
              ),
              convertToDBFormat(
                EDiabetesParameter.does_home_glucose,
                value.doesHomeGlucose as string
              ),
              convertToDBFormat(
                EDiabetesParameter.eat_drink_to_treat,
                value.eatDrinkToTreat as string
              ),
              convertToDBFormat(
                EDiabetesParameter.experiences_hypos,
                value.experiencesHypos as string
              ),
              convertToDBFormat(
                EDiabetesParameter.fasting_at_time_of_hypo,
                value.fastingAtTimeOfHypo as string
              ),
              convertToDBFormat(
                EDiabetesParameter.glucose_below_2p8,
                value.glucoseBelow2p8 as string
              ),
              convertToDBFormat(
                EDiabetesParameter.glucose_below_4,
                value.glucoseBelow4 as string
              ),
              convertToDBFormat(
                EDiabetesParameter.num_times_sugar_less_4,
                value.numTimesSugarLess4 as string
              ),
              convertToDBFormat(
                EDiabetesParameter.symptoms_during_hypos,
                value.symptomsDuringHypos as string
              ),
              convertToDBFormat(
                EDiabetesParameter.times_help_during_hypos,
                value.timesHelpDuringHypos as string
              ),
            ],
          },
          note: " ",
          datetime: DateTime.now().toString(),
        };

        HTTP_SERVICE.createEngagement(
          newClinicalEngagement as Partial<IEngagement>
        ).then((result: IEngagement | ErrorResponse): void => {
          if (!isErrorResponse(result)) {
            resetForm({ values: initialValues });
            setActiveStep(0);
            setTasks(tasks.filter((v,i)=>i !==0));
            if(tasks.length === 1){
              setAllFormSubmitted(true);
            }
          }
        });
      }
    },
  });

  const formContent = (step: number) => {
    switch (step) {
      case 0:
        return doesHomeGlucose(formik);
      case 1:
        return glucoseBelow4(formik);
      case 2:
        return glucoseBelow2p8(formik);
      case 3:
        return experiencesHypos(formik);
      case 4:
        return symptomsDuringHypos(formik);
      case 5:
        return timesHelpDuringHypos(formik);
      case 6:
        return dateLastHypo(formik);
      case 7:
        return bloodSugarToTreat(formik);
      case 8:
        return numTimesSugarLess4(formik);
      case 9:
        return eatDrinkToTreat(formik);
      case 10:
        return checkSugarBeforeDriving(formik);
      case 11:
        return fastingAtTimeOfHypo(formik);
      default:
        return <div>404: Not Found</div>;
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        mt: 1,
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item md={12} xs={12}>
          <Stepper activeStep={activeStep} orientation="horizontal">
            <Step>
              <Typography variant="h4" color='primary'>{`${activeStep + 1} /${steps.length}`}</Typography>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
      <form>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} sx={{ pt: "20px" }}>
            {formContent(activeStep)}
          </Grid>
          <Grid item xs={12}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mx: "10px", fontSize: 20 }} size="large">
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button onClick={formik.submitForm} size="large" sx={{ fontSize: 20 }} >Submit</Button>
            ) : (
              <Button onClick={handleNext} size="large" sx={{ fontSize: 20 }} >Next</Button>
            )}
          </Grid>
        </Grid>
        {activeStep === steps.length - 1 &&
          formik.errors &&
          Object.values(formik.errors).map((errorMessage, index) => (
             <Typography variant="h6" component='div' key={index} sx={{ color: "error.main" }}>
                {errorMessage}
             </Typography>
            ))
         }
      </form>
    </Box>
  );
};
