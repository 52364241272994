import {
  FormType,
  IReading,
} from "./questionnaire.interface";
import { enumToKeyValuePair, ListOptions } from "./utils";

export enum EEngagementTypes {
  clinical_reading = "clinical_reading",
  other = "other",
}

export type EngagementType = keyof typeof EEngagementTypes;

export const engagementTypeOptions = (): ListOptions<EngagementType>[] =>
  enumToKeyValuePair(EEngagementTypes);

export type IEngagementContent<T> = T;

export interface IEngagementBase<T> {
  _id: string;
  type: EngagementType;
  user: string;
  patient: string;
  content: IEngagementContent<T>;
  note?: string;
  audit_logs?: string[];
  datetime?: string;
}

export enum ESubmissionTypes {
  patient_blood_pressure_form_submission = "patient_blood_pressure_form_submission",
  patient_lifestyle_form_submission = "patient_lifestyle_form_submission",
  patient_diabetes_form_submission = "patient_diabetes_form_submission",
}

export type SubmissionType = keyof typeof ESubmissionTypes;

export const submissionTypeOptions = (): ListOptions<SubmissionType>[] =>
  enumToKeyValuePair(ESubmissionTypes);

export interface IClinicalReadingTemplate {
  formType?: FormType;
  submission: SubmissionType;
  readings: IReading[][];
}

export type IClinicalReading = Omit<
  IEngagementBase<IClinicalReadingTemplate>,
  "type"
> & {
  type: EEngagementTypes.clinical_reading;
};

export type IEngagement = IClinicalReading
