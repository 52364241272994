import { Box, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import PatientDetailFrom from "../../Forms/PatientDetailForm";

export const ProfilePage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        mt: 1,
      }}
    >
      <Grid
        container
      //   direction="row"
      //   alignItems="center"
      >
        <Grid item md={2} xs={0}>
          <IconButton size="large" onClick={() => navigate(-1)}>
            <ArrowBackSharpIcon />
          </IconButton>
        </Grid>
        <Grid item md={8} xs={12}>
          <PatientDetailFrom />
        </Grid>
        <Grid item md={2} xs={0}>
          {" "}
        </Grid>
      </Grid>
    </Box>
  );
};
