import { atom, selector } from "recoil";
import { IPatientPopulated } from "../interfaces/patient.interface";
import { IPatientTask } from "../interfaces/task.interface";
import { HTTP_SERVICE, isErrorResponse } from "./http.service";

export const patientForms = {
  PatientDetailFrom: "PatientDetailFrom",
  BloodPressureReadingFrom: "BloodPressureReadingFrom",
  DiabetesReadingForm: "DiabetesReadingForm",
  LifestyleReadingForm: "LifestyleReadingForm",
  none: "none",
} as const;

export const taskListAtom = atom<IPatientTask[]>({
  key: "taskListAtom",
  default: [],
});

export const patientAtom = atom<IPatientPopulated>({
  key: "selectedPatientAtom",
  default: undefined,
});

export const isAllFormSubmittedAtom = atom<boolean>({
  key: "isAllFormSubmittedAtom",
  default: false,
});

export const getLoggedPatient = selector<IPatientPopulated | null>({
  key: "loggedPatient",
  get: async () => {
    const response = await HTTP_SERVICE.authenticatedPatient();
    if (!isErrorResponse(response)) {
      return response;
    }
    return null;
  },
});
