export const basic = {
  primary: {
    main: "#2196f3",
  },
  secondary: {
    main: "#00bcd4",
  },
};

export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  grey: {
    light: "#F9F9F9",
    main: "#B1B5BF",
    dark: "#42495F",
    contrastText: "#B1B5BF",
  },
  info: {
    light: "#E4F0FD",
    main: "#3D8EFF",
    dark: "#2443A4",
    contrastText: "#3D8EFF",
  },
  success: {
    light: "#E9FCEF",
    main: "#51E16E",
    dark: "#2DA53C",
    contrastText: "#51E16E",
  },
  warning: {
    light: "#FFE8DA",
    main: "#F87010",
    dark: "#D24301",
    contrastText: "#F87010",
  },
  error: {
    light: "#FCE2EC",
    main: "#BA284B",
    dark: "#961F3A",
    contrastText: "#BA284B",
  },
  yellow: {
    light: "#FFF8E7",
    main: "#FFD15A",
    dark: "#EAA902",
    contrastText: "#FFD15A",
  }
};
