import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import GradingSharpIcon from "@mui/icons-material/GradingSharp";
import { Banner } from "../../Shared/Banner";
import {
  getLoggedPatient,
  isAllFormSubmittedAtom,
  patientAtom,
  taskListAtom,
} from "../../../services/state.service";
import { BPMultiStepForm } from "../../Forms/BPMultiStepForm";
import { DiabetesMultiStepForm } from "../../Forms/DiabetesMultiStepForm";
import { LifeStyleMultiStepForm } from "../../Forms/LifestyleMultiStepForm";
import { IPatientPopulated } from "../../../interfaces/patient.interface";
import { SuccessPage } from "../SuccessPage";
import {
  EPatientTaskTypes,
  ETaskStatusTypes,
  IPatientTask,
  ITask,
} from "../../../interfaces/task.interface";
import {
  EFormType,
  FormType,
} from "../../../interfaces/questionnaire.interface";

interface FormProps {
  formType: string;
}
const FormComponent = ({ formType }: FormProps) => {
  switch (formType) {
    case "blood_pressure_reading_form":
      return <BPMultiStepForm />;
    case "diabetes_questionnaire_form":
      return <DiabetesMultiStepForm />;
    case "lifestyle_questionnaire_form":
      return <LifeStyleMultiStepForm />;
    default:
      return null;
  }
};

export const PatientHome = () => {
  const patient = useRecoilValue(getLoggedPatient);
  const [tasks, setTasks] = useRecoilState(taskListAtom);
  const setPatient = useSetRecoilState(patientAtom);
  const isAllFormSubmitted = useRecoilValue(isAllFormSubmittedAtom);

  useEffect(() => {
    setPatient(patient as IPatientPopulated);

    const clinicalForms = [
      EFormType.blood_pressure_reading_form,
      EFormType.diabetes_questionnaire_form,
      EFormType.lifestyle_questionnaire_form,
    ] as FormType[];

    const filteredTasks = patient?.tasks
      .filter((task: ITask) => task.status.type === ETaskStatusTypes.active)
      .filter((task: ITask) => task.type === EPatientTaskTypes.submit_form)
      .filter(
        (task: ITask) =>
          (task as IPatientTask)?.formType &&
          clinicalForms.includes((task as IPatientTask).formType)
      ) as IPatientTask[];
    setTasks(filteredTasks);
  }, []);

  return (
    <Box
      sx={{
        mt: 1,
      }}
    >
      <Banner imageUrl="/banner.jpg" />

      {tasks.length > 0 && (
        <Grid container spacing={0} direction="column" alignItems="center">
          <FormComponent formType={tasks[0].formType} />
        </Grid>
      )}
      
      {tasks.length === 0 && isAllFormSubmitted === true && <SuccessPage />}

      {tasks.length === 0 && isAllFormSubmitted === false && (
        <>
          <Grid container direction="column" alignItems="center">
            <Grid item md={12} xs={12}>
              <GradingSharpIcon
                sx={{ fontSize: 170, color: "#00C2DD", mt: 2 }}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Grid item md={12} xs={12}>
              <Typography variant="h4">
                <strong>No Pending Task</strong>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
