import { Routes, Route, useSearchParams } from "react-router-dom";
// import { SignIn } from "./pages/SignIn";
import { URLS } from "./shared/constants";
import { Authenticate } from "./services/auth.service";
import { PatientHome } from "./pages/Patient/PatientHome";
import { PatientSignIn } from "./pages/Patient/PatientSignIn";
import { ProfilePage } from "./pages/Patient/ProfilePage";

interface AppRouterProps {
  defaultRoute: string;
}

const AppRouter = ({ defaultRoute }: AppRouterProps) => {
  const [urlQuery] = useSearchParams();
  const redirectURL = urlQuery.get("redirect") ?? "/";

  return (
    <Routes>
      <Route
        path={URLS.login}
        element={<PatientSignIn redirectURL={redirectURL} />}
      />

      <Route
        path={`${defaultRoute}/profile`}
        element={<Authenticate page={<ProfilePage />} />}
      />
      <Route
        path={defaultRoute}
        element={<Authenticate page={<PatientHome />} />}
      />
    </Routes>
  );
};

export default AppRouter;
