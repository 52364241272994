import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useFormik } from "formik";
import { dateOnly, localFormat } from "../../../services/datetime.service";
import {
  ErrorResponse,
  HTTP_SERVICE,
  isErrorResponse,
} from "../../../services/http.service";
import { AuthSuccessResponse } from "../../../interfaces/auth.interface";
import { persistToken } from "../../../services/auth.service";

type SignProps = {
  redirectURL: string | undefined;
};

export const PatientSignIn = ({ redirectURL }: SignProps) => {
  const [error, setError] = useState("");

  const schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    nhsNumber: z.number().gte(1).lte(10000000000,{ message: "NHS Number must contain at most 10 digit(s)" }), 
    day: z.number().int().gte(1).lte(31),
    month: z.number().int().gte(1).lte(12),
    year: z
      .number()
      .int()
      .gte(1920)
      .lte(
        Number((localFormat(new Date().toISOString()) as string).split("-")[0])
      ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      nhsNumber: "",
      day: "",
      month: "",
      year: "",
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      const dateParts =  `${values.year}-${(values.month).toString().padStart(2, "0")}-${(values.day).toString().padStart(2, "0")}`
      const dob = dateOnly(dateParts);
      HTTP_SERVICE.patientLogin({
        first: values.firstName,
        last: values.lastName,
        dateOfBirth: dob,
      }).then((result: AuthSuccessResponse | ErrorResponse) => {
        if (!isErrorResponse(result)) {
          persistToken(result);
          if (redirectURL) window.location.href = redirectURL;
        } else {
          setError(result.error.message);
        }
      });
    },
  });

  return (
    <Box
      sx={{
        mt: 15,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "60%" }}>
        {error && (
          <Box sx={{ pb: 2 }}>
            <Alert
              severity="error"
              onClose={() => {
                setError("");
              }}
            >
              {error}
            </Alert>
          </Box>
        )}
        <Box sx={{ pb: 2 }}>
          <Typography variant="h5">
            <strong>Let&apos;s confirm it&apos;s you</strong>
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} autoComplete="on">
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                type="text"
                name="firstName"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                fullWidth
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                type="text"
                name="lastName"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                fullWidth
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="NHS Number"
                variant="outlined"
                size="small"
                type="number"
                name="nhsNumber"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.nhsNumber && Boolean(formik.errors.nhsNumber)
                }
                helperText={formik.touched.nhsNumber && formik.errors.nhsNumber}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Whats your date of birth?</Typography>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Day (DD)"
                variant="outlined"
                size="small"
                type="number"
                name="day"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.day && Boolean(formik.errors.day)}
                helperText={formik.touched.day && formik.errors.day}
                fullWidth
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Month (MM)"
                variant="outlined"
                size="small"
                type="number"
                name="month"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.month && Boolean(formik.errors.month)}
                helperText={formik.touched.month && formik.errors.month}
                fullWidth
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Year (YYYY)"
                variant="outlined"
                size="small"
                type="number"
                name="year"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.year && Boolean(formik.errors.year)}
                helperText={formik.touched.year && formik.errors.year}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Button type="submit" variant="contained" size="small">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
